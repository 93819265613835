global.$ = global.jQuery = require('jquery');

// Vendor
import Swiper, {Navigation, Autoplay} from 'swiper';
import 'swiper/css';
window.Swiper = Swiper;
window.Navigation = Navigation;
window.Autoplay = Autoplay;

const fancybox = require("@fancyapps/fancybox");
$('[data-fancybox="gallery"]').fancybox({
    thumbs: {
        autoStart: true
    }
});

// Components
import '../../../partials/header/header'
import '../../../partials/burger/burger'
import '../../../partials/mobile-menu/mobile-menu'
import '../../../partials/google-map/google-map'
import '../../../partials/anchor/anchor'
import '../../../partials/carousel/carousel-featured-properties'
import '../../../partials/carousel/carousel-similar-properties.js'
import '../../../partials/slider/slider-default'
import '../../../partials/gallery/gallery'
