window.getHeaderHeight = function (){
    const header = document.getElementById('header');
    return header.offsetHeight
}

// scroll to anchor
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const element = document.querySelector(this.getAttribute('href'));
        const y = element.getBoundingClientRect().top + window.pageYOffset - getHeaderHeight();
        window.scrollTo({top: y, behavior: 'smooth'});
    });
});
