const header = document.querySelector('.js-header');
const headerHomePage = document.querySelector('.js-header-home');
const headerLogo = document.querySelector('.js-header-logo');
const headerLogoWithText = document.querySelector('.js-header-logo-with-text');
const headerDecor = document.querySelector('.js-header-decor');
const headerMenuLinks = document.querySelectorAll('.js-header-menu a');
const headerMenuLinksArrows = document.querySelectorAll('.js-header-menu-arrow');

window.onscroll = function () {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        if (headerHomePage) {
            headerHomePage.classList.add('!bg-white');
        }
        headerLogo.classList.remove('hidden');
        headerLogoWithText.classList.add('hidden');
        headerDecor.classList.add('!hidden');
        headerMenuLinks.forEach(function (item) {
            item.classList.add('!py-4');
        });
        headerMenuLinksArrows.forEach(function (item) {
            item.classList.add('!bottom-1');
        });
    } else {
        if (headerHomePage) {
            headerHomePage.classList.remove('!bg-white');
        }
        headerLogo.classList.add('hidden');
        headerLogoWithText.classList.remove('hidden');
        headerDecor.classList.remove('!hidden');
        headerMenuLinks.forEach(function (item) {
            item.classList.remove('!py-4');
        });
        headerMenuLinksArrows.forEach(function (item) {
            item.classList.remove('!bottom-1');
        });
    }
};
